/* You can add global styles to this file, and also import other style files */




html, body { min-height: 100vh; scroll-behavior: smooth;}
body { margin: 0px; font-family: Roboto, "Helvetica Neue", sans-serif;}



// Import customs variables
// @import "styles/variables";



// Import functions, variables, and mixins needed by other Bootstrap files
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

// Import Bootstrap Reboot
@import "/node_modules/bootstrap/scss/root"; // Contains :root CSS variables used by other Bootstrap files
@import "/node_modules/bootstrap/scss/reboot";

// Import Bootstrap Grid System
@import "/node_modules/bootstrap/scss/grid"; // Adds .container and .container-fluid classes and the grid system

// Import Bootstrap Utilities
@import "/node_modules/bootstrap/scss/utilities"; // Configures the utility classes that should be generated
@import "/node_modules/bootstrap/scss/tables"; // Adds classes for table
